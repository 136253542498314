.search{
    max-width: 282px;
}
.search .input{
    border: 0;
    outline: 0;
    height: 39px;
    width: 100%;
    background-color: transparent;
    font-size: 0.8rem;
}