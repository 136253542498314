:root{
  --asset-color:#278FD9;
}
*{
  margin: 0;
  padding: 0;
}
select{
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  color: #687182;
  margin: 0;
}
body{
  background-color: #F5F5F5;
}
a{
  text-decoration: none;
  color:inherit;
}

.menuBar .active .icon{
  background: var(--asset-color) !important;
  color: #fff !important;
}

.menuBar .active .menu-text {
  color: #000 !important;
}

.inputStyle input{
  width: 100%;
  background: linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF;
  border: 1.07838px solid #E0E4EC;
  border-radius: 5px;
  height: 38px;
  margin: 0.3rem 0;
  color: #8D98AA;
  padding: 0 0.5rem;
}
input:focus{
  outline: none;
}
.link{
  color: var(--asset-color);
  font-weight: 500;
  cursor: pointer;
}
.img-fluid{
  width: 100%;
  height: auto;
  margin-bottom: -5px;
}