@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

div.ql-editor, div.ql-editor-blank{
  background: linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF;
}

table.specificationTable{
  width: 100%;
  border-radius: 11px;
  border-spacing: 0;
}

.select{
  height: 30px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 32px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

table.specificationTable,td.specificationTable, th.specificationTable{
  border: 0.5px solid #e9e9e9;
}


.specificationTable th:first-child {
  border-top-left-radius: 11px;
  /* border-bottom-left-radius: 10px; */
}


.specificationTable td:first-child{
  border-bottom-left-radius: 11px;
}

.specificationTable td:last-child{
  border-bottom-right-radius: 11px;
}

.specificationTable th:last-child {
  border-top-right-radius: 11px;
  /* border-bottom-right-radius: 10px; */
}

.MuiAutocomplete-input {
  width: 100% !important;
}

.inputBox, select {
    width: 100%;
    padding: 12px 20px;
    height: 55px;
    margin: 5px 0;
    display: inline-block;
    border: 1px solid #E0E4EC;
    border-radius: 12px;
    box-sizing: border-box;
    background: linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF;
    font-family: 'Manrope', sans-serif;
    border-right: 16px solid transparent
  }

  .disabled{
    opacity: 0.6;
  }

  label{
    font-family: 'Manrope', sans-serif;
  }

  select option{
    font-family: 'Manrope', sans-serif;
  }

  .uploadimg{
    height: 130px;
    width: 130px;
  }

  .table, .th, .td {
    border: 1px solid gray;
    border-collapse: collapse;
  }

  .th{
    height: 50px;
  }
  .td{
    height: 80px;
  }



  
